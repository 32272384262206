<template>
  <VariableKpiKorporatForm mode="Tambah" module="Variable Indikator Kinerja"> </VariableKpiKorporatForm>
</template>

<script>
import VariableKpiKorporatForm from './form';

const VariableKpiKorporatAdd = {
  name: 'VariableKpiKorporatAdd',
  components: { VariableKpiKorporatForm },
};

export default VariableKpiKorporatAdd;
</script>
